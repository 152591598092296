import React from 'react'
import LandingPageIndex, { defaultData, LPIinterface } from '../LandingPageIndex.tsx'
import { HeaderContentProps } from '../LandingPageIndex.tsx';

const CyberSecurity = () => {
    const body: LPIinterface = {
        aboutWebinar: {
            title: "Courses Offered",
            description: "Q Academy a number of courses in this domain including.",
            buttonTitle: "Request Course Information",
            pointsArray: ["Advance certificate programme in Cyber Security & Ethical Hacking"],
        },

        whyJoin: {
            array: [{
                icon: 'fa fa-calendar',
                title: 'Upskill',
                text: 'Future-proof and sharpen you Cyber Security Skills',
                image: '/assets/images/raodmap/challenge.png'
            },
            {
                icon: 'fa fa-calendar',
                title: 'Boost your career',
                text: 'Boost Your Career: Gain insights into the job market for Cyber Security professionals and learn the technical and strategic skills',
                image: '/assets/images/raodmap/shuttle.png'
            },
            {
                icon: 'fa fa-calendar',
                title: 'Increase your earning potential',
                text: 'Increase Your Earning Potential: Get better career opportunities with improved skills ',
                image: '/assets/images/raodmap/revenue.png'
            },
            {
                icon: 'fa fa-calendar',
                title: 'Develop a winning data strategy ',
                text: 'Develop a Winning AI Strategy: Learn how to build a roadmap for success in Cyber Security',
                image: '/assets/images/raodmap/ribbon.png'
            },]
        },

        howItHelps: {
            // Network Security, Cryptography, Peneteration Testing, Ethical Hacking
            skills: [
                {
                    iconImage: "/assets/images/icons/Cyber Security/Network Security.png",
                    text: "Network Security",
                },
                {
                    iconImage: "/assets/images/icons/Cyber Security/Cryptography.png",
                    text: "Cryptography",
                },
                {
                    iconImage: "/assets/images/icons/Cyber Security/Peneteration testing.png",
                    text: "Peneteration Testing",
                },
                {
                    iconImage: "/assets/images/icons/Cyber Security/Ethical Hacking.png",
                    text: "Ethical Hacking",
                },
            ],

            array: [
                {
                    title: 'Future-proof career',
                    text: 'These courses will future-proof your career and help you ace the tech world with new trends',
                    image: '/assets/images/raodmap/goal.png'
                },
                {
                    title: 'Adaptability and resilience',
                    text: 'All the modules covered will help you be adaptable to the ongoing challenges in the tech industry',
                    image: '/assets/images/raodmap/adapt.png'
                },
                {
                    title: 'Continuous learning',
                    text: 'The best opportunity to get along with tech trends',
                    image: '/assets/images/raodmap/continuous-learning.png'
                },
                {
                    title: 'Competitive advantage',
                    text: ' Learn the updated strategies and advance your career.',
                    image: '/assets/images/raodmap/advantage.png'
                }
            ]
        },

        registerNow: {
            buttonTitle: "Book a Free Counseling Session",
        }

    };

    const head1: HeaderContentProps = {
        buttonTitle: "Book a Free Counseling Session",
        data: { ...defaultData, mainTitle: 'Industry-Driven Cyber Security Courses for Aspiring Professionals!', }
    };
    const head2: HeaderContentProps = {
        buttonTitle: "Contact Us About This Course",
    };

    return <LandingPageIndex body={body} Head1={head1} Head2={head2} />;
}

export default CyberSecurity
