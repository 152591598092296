import { Card, Carousel, Col, Container, FormControl, Image, Modal, Row } from "react-bootstrap"
import CommonParent from "../Common/CommonPartent"
import Slider from "react-slick"
import { useDispatch, useSelector } from "react-redux";
import { setIsSearchFieldShow } from "../../app/reducers/courseReducer";
import { HeaderContent } from "../../pages/Roadmap/LandingPage";
import { HeaderContent as R } from "../../pages/Roadmap/Roadmap";
import React, { useEffect } from "react";


const Banner = () => {

    const dispatch = useDispatch();
    const { isLogin } = useSelector((state) => state?.user || {});

    const bannerData = [
        {
            id: 1,
            image: "/assets/images/home/image1.png",
            imagePosition: "center",
            title: "<h3>Innovate. Educate. Grow. Discover:</h3><h3>The Latest Professional</h3><h3>Certificate Courses</h3>",
            subDescription: "<p>Discover professional courses that</p><p>can build job-ready skills and boost your</p><p>career. Expand your professional skills to achieve </p><p>your career aspirations.</p>",
            Style_For_Q_Image: "Q_Image",
            interval: undefined,
            searchOnClick: () => dispatch(setIsSearchFieldShow(true)),
        },
        {
            id: 2,
            image: "/assets/images/home/Second_Banner.png",
            imagePosition: "bottom",
            title: "Empowering",
            description: "A Generation of Leaders with Cutting-Edge Ed-Tech Solutions",
            subDescription: "<p>The latest tools and ed tech solutions on your </p> fingertips",
            Style_For_Q_Image: "Q_Image",
            interval: undefined,
            searchOnClick: () => { dispatch(setIsSearchFieldShow(true)) },
        },
    ]


    var setting = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        cssEase: "ease-in-out",
        pauseOnHover: false,
    };

    const [showAnnouncement, setShowAnnouncement] = React.useState(true);
    const handleCloseAnnouncement = () => setShowAnnouncement(false);
    const handleShowAnnouncement = () => setShowAnnouncement(true);

    useEffect(() => {
        const CountryCodeGet = localStorage.getItem("country");
        if (!isLogin && CountryCodeGet === "IN") {
            handleShowAnnouncement();
        }
    }, [isLogin])

    return (
        <CommonParent>
            {/* <Modal size='xl' show={showAnnouncement} onHide={() => handleCloseAnnouncement()}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Slider {...setting}>
                        <R />
                        <HeaderContent />
                    </Slider>
                </Modal.Body>
            </Modal> */}
            <center>
                <div className="bannerContainer">
                    <Slider {...setting}>
                        {bannerData.map((data, index) => {
                            return (
                                <>
                                    <div className="carouselStyle" key={index} style={{
                                        backgroundImage: `url(${data.image})`,
                                        backgroundPosition: data.imagePosition,
                                    }}>
                                        <Container className="text-align-vertical-center QWatermark">
                                            <div className="bannerContent">
                                                <h3 dangerouslySetInnerHTML={{ __html: data.title }} />
                                                <h4>{data?.description}</h4>
                                                <Row className="mt-4 mb-3" style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexDirection: "row",
                                                    width: "100%",
                                                }}>
                                                    <FormControl readOnly type="text" className="bannerSearchInputField" value={"Explore professional courses"} onClick={data.searchOnClick} />
                                                    <div className="fa fa-search bannerSearchIcon" aria-hidden="true" onClick={data.searchOnClick} />
                                                </Row>
                                                <p className="text-black" dangerouslySetInnerHTML={{ __html: data.subDescription }} style={{
                                                    lineHeight: 0.5,
                                                }} />
                                            </div>
                                        </Container>
                                    </div>
                                </>
                            )
                        }
                        )
                        }
                    </Slider>
                    <ThereCards />
                </div>
            </center>
        </CommonParent>
    )
}

export default Banner

export const ThereCards = () => {
    const cardData = [
        {
            id: 1,
            title: "80+",
            description: "Professional Certificate Courses",
            className: "text-danger fontCustomStyleTitleBannerPart",
            descriptionClassName: "fontCustomStyleDescriptionBannerPart",
        },
        {
            id: 2,
            title: "100+",
            description: "Highly Skilled Educators",
            className: "text-primary fontCustomStyleTitleBannerPart",
            descriptionClassName: "fontCustomStyleDescriptionBannerPart",
        },
        {
            id: 3,
            title: "4000+",
            description: "Success Stories",
            className: "text-success-color fontCustomStyleTitleBannerPart",
            descriptionClassName: "fontCustomStyleDescriptionBannerPart",
        },
    ]

    return (
        <Container style={{
            height: 0,
        }}>
            <div className="banner3cards">
                {cardData.map((data, index) => {
                    return (
                        <Card className="text-center shadow bannerCardCustom">
                            <Card.Body>
                                <h3 className={data.className} >{data.title}</h3>
                                <Card.Text className={data.descriptionClassName}>{data.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    )
                })}
            </div>
        </Container>
    )
}